<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		<!-- <Load v-if="isLoading"></Load> -->
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<div v-if="!isLoading && museum" class="container">
			<img class="bg-image" :src="museum.walktroughImageUrl" alt="">
		</div>
		
		<main v-if="!isLoading && questions.length">
			<div class="logo">
				<img class="logo-image" :src="museum.logoImageUrl" :alt="museum.locale ? museum.locale.name : ''">
			</div>
			
			<div class="question-container">
				<!-- Question title -->
				<h1 v-if="currentQuestion.locale">{{ currentQuestion.locale.title }}</h1>
				
				<!-- Question answers -->
				<div v-for="answer in currentQuestion.answers" :key="answer.idStudyQuestionAnswer" class="question-answer">
					<!-- Text answer -->
					<div v-if="answer.type === 'TEXT' || answer.type === 'DATE'" class="answer">
						<label v-if="answer.locale" class="answer-label" :for="answer.idStudyQuestionAnswer">{{ answer.locale.label }}</label>
						<input :id="answer.idStudyQuestionAnswer" v-model="currentQuestion.visitAnswers[answer.idStudyQuestionAnswer].value" :type="answer.type">
					</div>
					
					<!-- Textarea answer -->
					<div v-if="answer.type === 'TEXTAREA'" class="answer">
						<label v-if="answer.locale" class="answer-label" :for="answer.idStudyQuestionAnswer">{{ answer.locale.label }}</label>
						<textarea :id="answer.idStudyQuestionAnswer" v-model="currentQuestion.visitAnswers[answer.idStudyQuestionAnswer].value" rows="5"/>
					</div>
					
					<!-- Select answer -->
					<div v-if="answer.type === 'SELECTOR'" class="answer">
						<span v-if="answer.locale" class="answer-label">{{ answer.locale.label }}</span>
						<ul class="options single">
							<li v-for="option in answer.options" :key="option.idStudyQuestionAnswerOption"
							    class="option"
							    :class="{ 'active': currentQuestion.visitAnswers[answer.idStudyQuestionAnswer].value === option.locale.label }"
							    @click="setAnswer(answer, false, option.locale.label)">
								{{ option.locale.label }}
							</li>
						</ul>
					</div>
					
					<!-- Multiple answer -->
					<div v-if="answer.type === 'MULTIPLE'" class="answer">
						<span v-if="answer.locale" class="answer-label">{{ answer.locale.label }}</span>
						<ul class="options">
							<li v-for="option in answer.options" :key="option.idStudyQuestionAnswerOption"
							    class="option"
							    :class="{ 'active': isAnswerSelected(answer, option.locale.label) }"
							    @click="setAnswer(answer, true, option.locale.label)">
								{{ option.locale.label }}
							</li>
						</ul>
					</div>
					
					<!-- Rating answer -->
					<div v-if="answer.type === 'RATING'" class="answer">
						<span v-if="answer.locale" class="answer-label">{{ answer.locale.label }}</span>
						<ul class="star-rating">
							<li v-for="index in 5" :key="index"
							    class="option"
							    :class="{ 'active': currentQuestion.visitAnswers[answer.idStudyQuestionAnswer].value >= index }"
							    @click="currentQuestion.visitAnswers[answer.idStudyQuestionAnswer].value = index">
								<i class="mm-icon mm-icon-star-rating-filled big"/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</main>
		
		<nav class="navigation-dots">
			<ul>
				<li v-for="question in questions" :key="question.idStudyQuestion"
				     class="dot"
				    :class="{'active': index === question.index}"
				    @click="index === question.index"/>
			</ul>
		</nav>
		
		<!-- Study question controls -->
		<footer>
			<div class="footer-buttons">
				<!-- Previous question -->
				<button v-show="index > 0" class="button-white" @click="index--">
					<i class="mm-icon mm-icon-arrow-back big"/>
				</button>
				
				<!-- Exit questions -->
				<button :disabled="!allowToContinue()" class="button-blue" @click="endQuestion()">
					<i v-show="allowToContinue()" class="mm-icon big" :class="{ 'mm-icon-arrow-right': index < (questions.length - 1), 'mm-icon-check': (index === questions.length - 1) }"/>
					<span v-show="!allowToContinue()">{{ $t('complete_questions') }}</span>
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
import StudiesAPI from "@/api/StudiesAPI";
const {debug} = require("@/misc/debug");
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import {findLocalization, setFullScreen} from "@/store/helpers";
import {mapActions, mapState} from "vuex";
import * as mutationTypes from "@/store/mutation-types";
import StudyQuestion from "@/models/study-question";
import StudyQuestionAnswer from "@/models/study-question-answer";
import VisitStudyQuestionAnswer from "@/models/visit-study-question-answer";
// import Load from '../../components/Load.vue';

export default {
	name: "StudyQuestion",
	
	components: {
		// Load,
		Loading
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idArtwork: this.$route.params.idArtwork,
			idMuseum: "0",
			questions: [],
			index: 0,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			studyQuestions: state => state.studyQuestion.studyQuestions,
			studyQuestionsError: state => state.studyQuestion.studyQuestionsError,
			visit: state => state.app.visit,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			viewFrom: state => state.app.viewFrom,
		}),
		
		/**
		 * Current question being showed
		 * @returns {StudyQuestion}
		 */
		currentQuestion() {
			return this.questions.find(q => q.index === this.index);
		},
		
		pageTitle() {
			let locationName = this.$i18n.t('survey');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		cssVars() {
			return {
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-color-light': (this.customization?.mainButtonsCustomizationEnabled) ? (this.customization.mainButtonsColor + 'AA') : '#72c4d0AA',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		}
	},
	
	created() {
		debug.open("Study");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(async() => {
					this.getStudyQuestions(this.idArtwork);
					this.questions = this.processQuestions();
					
					debug.close();
					await this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('studyQuestion', ['getStudyQuestions']),
		
		processQuestions() {
			debug.open("Processing questions");
			let questions = [];
			
			this.studyQuestions.forEach(q => {
				let question = new StudyQuestion();
				question.parseJSON(q);
				let visitAnswers = {};
				
				//Parse answers and find their locales
				question.answers = q.answers.map(a => {
					let answer = new StudyQuestionAnswer();
					answer.parseJSON(a);
					answer.options = a.options.map(o => {
						let option = o;
						option.locale = findLocalization(option);
						return option;
					});
					answer.locale = findLocalization(answer);
					
					//Create visit answers objects
					let visitAnswer = new VisitStudyQuestionAnswer();
					visitAnswer.idVisit = this.visit.idVisit;
					visitAnswer.idStudyQuestionAnswer = answer.idStudyQuestionAnswer;
					visitAnswers[answer.idStudyQuestionAnswer] = visitAnswer;
					
					return answer;
				});
				question.visitAnswers = visitAnswers;
				question.locale = findLocalization(question);
				question.index = questions.length;
				questions.push(question);
			});
			
			debug.close();
			return questions;
		},
		
		allowToContinue() {
			if(!this.currentQuestion?.mandatory) {
				return true;
			}
			
			//Check if there is any answer without value
			return Object.values(this.currentQuestion.visitAnswers).every((a => a.value != undefined && a.value !== ""));
		},
		
		isAnswerSelected(answer, value) {
			const answers = this.currentQuestion.visitAnswers[answer.idStudyQuestionAnswer]?.value?.split(',');
			return answers?.includes(value);
		},
		
		setAnswer(answer, isMultiple, value) {
			let answerValue = this.currentQuestion.visitAnswers[answer.idStudyQuestionAnswer].value;
			if(isMultiple && answerValue){
				let answers = answerValue.split(',');
				if(answers.includes(value)) {
					answerValue = answers.filter(a => a !== value).join(',');
				} else {
					answerValue = answerValue + ',' + value;
				}
				
			} else {
				answerValue = value;
			}
			this.currentQuestion.visitAnswers[answer.idStudyQuestionAnswer].value = answerValue;
		},
		
		setFullScreen(to = null) {
			setFullScreen();
			if (to) {
				this.$router.push(to);
			}
		},
		
		endQuestion() {
			setFullScreen();
			const visitAnswers = Object.values(this.currentQuestion.visitAnswers).filter(a => a.value != undefined && a.value !== "");
			
			//send answers
			StudiesAPI.sendAnswers(this.idMuseum, visitAnswers)
			.then(response => {
				if(response.data.success) {
					if (this.index === (this.questions.length - 1)) {
						this.exitStudy();
						return;
					}
					
					//Change question being showed
					this.index++;
				} else {
					console.error(response.data.message);
				}
			})
			.catch(error => {
				console.error(error);
			});
		},
		
		exitStudy() {
			//Save that all the questions at the start of the visit have been saved
			if(!this.idArtwork) {
				localStorage.setItem('start-studies-made', true);
			} else {
				localStorage.setItem('study-artwork-' + this.idArtwork, true);
			}
			
			//Redirect based on where is the study set
			if(this.idArtwork) {
				if (this.viewFrom.name === 'Beacon') {
					this.$router.go(-1);
				} else if(this.viewFrom.name === 'Route'
					|| this.viewFrom.name === 'Exhibition'
					|| this.viewFrom.name === 'Timeline') {
					this.$router.push({name: this.viewFrom.name, params: this.viewFrom.params, query: { from: this.idArtwork } });
				} else {
					this.$router.push({name: this.viewFrom.name, params: this.viewFrom.params});
				}
			} else {
				this.$router.push({name: 'Options', params: {idMuseum: this.encrypted}});
			}
		}
	}
	
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	height: 100%;
	
	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: -1;
		
		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
		}
		
		&::after {
			content: "";
			position: absolute;
			inset: 0;
			background-color: rgba(0, 0, 0, .3);
		}
	}
	
	main {
		width: 100%;
		height: calc(100% - 80px);
		padding: 20px;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		
		.logo {
			width: 100%;
			display: flex;
			justify-content: center;
			
			.logo-image {
				max-width: 70%;
				max-height: 100px;
			}
		}
		
		h1 {
			color: white;
			margin-top: 25px;
			font-size: 22px;
		}
		
		.question-container {
			.question-answer {
				margin: 20px 0;
				
				.answer {
					display: flex;
					flex-direction: column;
					height: 100%;
					
					.answer-label {
						margin-bottom: 10px;
						color: white;
					}
					
					input, textarea {
						border: 2px solid white;
						color: white;
						padding: 5px;
						background-color: transparent;
					}
					
					::-webkit-calendar-picker-indicator {
						filter: invert(100%);
					}
					
					.star-rating {
						display: flex;
						justify-content: space-around;
						list-style: none;
						
						.option {
							cursor: pointer;
							padding: 10px;
							color: white;
							
							&.active {
								color: #437FA5;
							}
						}
					}
					
					.options {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-evenly;
						list-style: none;
						
						&.single {
							.option {
								width: 100%;
							}
						}
						
						.option {
							cursor: pointer;
							color: white;
							background-color: transparent;
							border: 2px solid white;
							padding: 10px;
							margin-bottom: 10px;
							
							&.active {
								background-color: #437FA5;
								color: white;
								border: 2px solid #437FA5;
							}
						}
					}
				}
			}
		}
	}
	
	.dots {
		width: 100%;
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		
		.dot {
			border-radius: 100%;
			margin-right: 10px;
			width: 7px;
			height: 7px;
			background-color: #a2a2a2;
			
			&.active {
				width: 10px;
				height: 10px;
				background-color: white;
			}
		}
	}
	
	footer {
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		
		.footer-buttons {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: center;
			background-color: white;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				
				&.button-white {
					background-color: white;
					color: black;
				}
				
				&.button-blue {
					background-color: var(--main-buttons-color);
					color: var(--main-buttons-text-color);
					
					.mm-icon {
						color: var(--main-buttons-text-color);
					}
					
					&:disabled {
						background-color: var(--main-buttons-color-light);
					}
				}
			}
		}
	}
}
</style>
